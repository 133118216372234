import React from 'react'
import styled from 'styled-components'
import { Row, Col } from 'react-styled-flexboxgrid'
import {
  FacebookShareButton,
  TwitterShareButton,
  LinkedinShareButton,
  EmailShareButton,
  EmailIcon,
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
} from 'react-share'

import Title from './Title'
import Image from './Image'

import { renderer, renderDate } from '../utils/prismic'

const Heading = styled(Title)`
  color: ${({ theme }) => theme.colors.primary};
  margin-top: 32px;
  margin-bottom: 48px;
  font-size: 32px;
  @media (min-width: 1400px) {
    font-size: 72px;
  }
`
const ImageWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-bottom: 16px;
  background: ${({ theme }) => theme.colors.gray10};
  z-index: 1001;

  @media (min-width: ${({ theme }) => theme.breakpoints.md}px) {
    margin-bottom: 32px;
  }
  img {
    display: block;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`
const AricleWrapper = styled.div`
  z-index: 1001;
`

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
`
const SocialLinks = styled.div`
  button {
    margin-left: 8px;
  }
`

const BlogList = ({ articleTitle, content, date, image, imageSharp }) => {
  const postURL = typeof window !== 'undefined' ? window.location.href : ''
  return (
    <Row>
      <Col xs={8} xsOffset={1} md={6} mdOffset={2}>
        <AricleWrapper>
          <Heading tag="h1" content={articleTitle} />
          <Wrapper>
            <div>{renderDate(date)}</div>
            <SocialLinks>
              <FacebookShareButton url={postURL}>
                <FacebookIcon size={26} round bgStyle={{ fill: '#1700DE' }} />
              </FacebookShareButton>
              <TwitterShareButton url={postURL}>
                <TwitterIcon size={26} round bgStyle={{ fill: '#1700DE' }} />
              </TwitterShareButton>
              <LinkedinShareButton url={postURL}>
                <LinkedinIcon size={26} round bgStyle={{ fill: '#1700DE' }} />
              </LinkedinShareButton>
              <EmailShareButton url={postURL}>
                <EmailIcon size={26} round bgStyle={{ fill: '#1700DE' }} />
              </EmailShareButton>
            </SocialLinks>
          </Wrapper>
          <ImageWrapper>
            <Image image={image} {...imageSharp} />
          </ImageWrapper>
          <div>{renderer(content)}</div>
        </AricleWrapper>
      </Col>
    </Row>
  )
}

export default BlogList
