import React from 'react'
import { graphql } from 'gatsby'
import get from 'lodash/get'

import Content from '../components/Content'
import Layout from '../components/Layout'
import Separator from '../components/Separator'
import BlogPost from '../components/BlogPost'

export const query = graphql`
  query ArticleQuery($uid: String) {
    prismic {
      documents: allArticles(uid: $uid) {
        edges {
          node {
            metaTitle: meta_title
            metaDescription: meta_description
            metaImage: meta_image
            articleTitle: title
            content
            date
            image
            imageSharp {
              childImageSharp {
                fluid(maxWidth: 1500) {
                  ...GatsbyImageSharpFluid_withWebp_noBase64
                }
              }
            }
          }
        }
      }
    }
  }
`

export default props => {
  const data = get(props, 'data.prismic.documents.edges[0].node')

  return (
    <Layout {...data}>
      <Separator />
      <Content>
        <BlogPost {...data} />
      </Content>
    </Layout>
  )
}
